import React from "react"
import Layout from "@components/layout";
import FormsParts from "@components/forms-contactform7";

// 関数呼び出し
import { SeoContents } from "@components/seo-data.js" // Seo情報設定

const Contact = () => {

  // Seo情報設定
  const pathName = 'contact';
  const seoTag = SeoContents({ Path:pathName });
 
  return (
  <>
    <Layout>
      {seoTag}
      <div> 
        {/* FV */}
        <div className="corp_h1">
          <h1 className="font-semibold ">Contact</h1>
        </div>
        <div className="md:max-w-2xl  mx-auto px-6 pt-5  md:pt-12"> 
          <div className="text-center mb-[50px]  "> 
            <h2 className="font-semibold tracking-wide text-xl md:text-[18px]">Contact from</h2>
  
          </div>
          <div className="mb-[100px]">
            <FormsParts/>
          </div>
        </div>
      </div>
    </Layout> 
  </>
  )
}
export default Contact
